import { useEffect, useState } from 'react';
import { Container, Header, ContentLayout, SpaceBetween, ColumnLayout, Box, StatusIndicator, Tabs, BreadcrumbGroup } from "@cloudscape-design/components";
import { useNavigate } from 'react-router-dom';

import "../css/base.scss";

import { SiteDetailsHeader } from "../components/header";
// import RenameModal from "../components/site-rename-modal";
// import DeleteModal from "../components/site-delete-modal";
import SiteStats from "../components/site-stats";
import SitePlugins from "../components/site-plugins";
import SiteLogs from "../components/site-logs";
import SiteBackups from "../components/site-backups";
import SiteEvents from '../components/site-events';
import { useParams } from 'react-router';
import { loggedIn, axiosGet, capitalizeFirst, useQuery } from "../components/common";
import { siteDetailsBreadcrumbs, putSite } from "../components/common";
import ShowNotifications from '../components/notifications';
import RenameModal from "../components/site-rename-modal";
import DeleteModal from "../components/site-delete-modal";
import SuspendModal from "../components/site-suspend-modal";
import UpgradeModal from '../components/site-upgrade-modal';
import BackupModal from '../components/site-backup-modal';
import TemplateModal from '../components/site-template-modal';
import sitelogin from "../components/sitelogin";

const Breadcrumbs = ({navigate, site}) => (
  <BreadcrumbGroup
    onFollow={event => {
      event.preventDefault();
      navigate(event.detail.href);
    }}
    items={siteDetailsBreadcrumbs(site)}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs" />
)

export function loadData(data) {
  if (isNaN(data.siteId)) {
    let result = {
      name: "Error",
      error: "invalid site id, must be a number.",
    }
    data.setData(result);
    return null;
  }
  // props.setLoading(true)
  axiosGet("sites/" + data.siteId + "?pretty=1")
  .then((response) => {
    if (response.data.error !== false) {
      let result = {
        name: "Error",
        error: response.data.error,
      }
      data.setData(result);
      return null;
    }else {
      // console.log(response);
      if (response.data.result.length === 0) {
        let result = {
          name: "Error",
          error: "No site found with that id",
        }
        data.setData(result);
        return null;
      }
      data.setData(response.data.result[0]);
    }
  })
  .catch((error) => {
    // console.log(error);
    let result = {
      name: "Error",
      error: error.message,
    }
    data.setData(result);
    return null;
  });
}



function Content(props) {
  const query = useQuery();
  const navigate = useNavigate();
  const [ activeTabId, setActiveTabId ] = useState(query.get("tab") || "details");

  useEffect(() => {
    loadData(props.data);
  }, []);

  if (props.data.siteData === null) {
    return null;
  }

  // console.log(props.data.siteData);
  const siteName = props.data.siteData !== null ? props.data.siteData.name : 'Loading...';
  const tabs = [
    {
      label: 'Details',
      id: 'details',
      href: '?tab=details',
      content: "Details",
    },
    {
      label: 'Config',
      id: 'config',
      href: '?tab=config',
      content: "Config",
    },
    {
      label: 'Backups',
      id: 'backups',
      href: '?tab=backups',
      // content: "Backups",
      content: <SiteBackups flashBar={props.flashBar} siteId={props.data.siteId} data={{ "siteData": props.data.siteData, "setReload": props.data.setReload}}/>,
    },
    {
      label: 'Logs',
      id: 'logs',
      href: '?tab=logs',
      // content: <LogsTable />,
      content: <SiteLogs site={props.data.siteData.name} />,
    },
    {
      label: 'Stats',
      id: 'stats',
      href: '?tab=stats',
      content: <SiteStats site="test.workdemo.ethinksites.com"/>,
    },
    {
      label: 'Plugins',
      id: 'plugins',
      href: '?tab=plugins',
      content: <SitePlugins site="test.workdemo.ethinksites.com"/>,
    },
    {
      label: 'Events',
      id: 'events',
      href: '?tab=events',
      content: <SiteEvents siteId={props.data.siteId}/>,
    },
    {
      label: 'Tags',
      id: 'tags',
      href: '?tab=tags',
      content: "Tags",
    },
  ];

  return (
    <SpaceBetween size="l">
      <Container header={<Header variant="h2">General configuration</Header>}>
        <ColumnLayout columns={4} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Product</Box>
            <div>{props.data.siteData.currentversion}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Stack</Box>
            <div>{props.data.siteData.stack}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Load Balancer</Box>
            <div>{props.data.siteData.lbhost}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Status</Box>
            <div>
              {
                (props.data.siteData.status == "active") ? (
                  <StatusIndicator type="success">{capitalizeFirst(props.data.siteData.status)}</StatusIndicator>
                ) : (
                  <StatusIndicator type="info">{capitalizeFirst(props.data.siteData.status)}</StatusIndicator>
                )
              }
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Data Store</Box>
            <div>{props.data.siteData.dataroot}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">S3 Bucket</Box>
            <div>{props.data.siteData.s3bucket}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Template</Box>
            <div>
              {
                (props.data.siteData.template) ? (
                  <StatusIndicator type="success">Yes</StatusIndicator>
                ) : (
                  "No"
                )
              }
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Pending Actions</Box>
            <div>
              {
                (props.data.siteData.status === "pending") ? (
                  <StatusIndicator type="in-progress">
                    {
                      (props.data.siteData.state === "upgrading") ? (
                        "Upgrading to " + props.data.siteData.targetversion
                      ) : (
                        capitalizeFirst(props.data.siteData.state)
                      )
                    }
                    </StatusIndicator>
                ) : (
                  "No"
                )
              }
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">DB Cluster</Box>
            <div>{props.data.siteData.dbhost}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Cache Host</Box>
            <div>{props.data.siteData.cachehost}</div>
          </div>
          {/* <div>
            <Box variant="awsui-key-label">DB Cluster Status</Box>
            <StatusIndicator type="success">Available</StatusIndicator>
          </div> */}
        </ColumnLayout>
      </Container>
      <Tabs
        tabs={tabs}
        ariaLabel="Resource details"
        variant='container'
        activeTabId={activeTabId}
        onChange={
          event => {
            setActiveTabId(event.detail.activeTabId);
            navigate(event.detail.activeTabHref);
          }
        }
      />
    </SpaceBetween>
  );
}

export default function SiteDetails({ state }) {
  const [reload, setReload] = useState(false);
  const [ loginloading, setLoginLoading ] = useState(false);
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [ siteData, setSiteData ] = useState(null);

  const data = {
    siteId: parseInt(siteId),
    siteData: siteData,
    setData: setSiteData,
    setReload: setReload,
  }
  const siteName = data.siteData !== null ? data.siteData.name : 'Loading...';
  if (!loggedIn()) {
    console.log("Not logged in");
    return null;
  }

  useEffect(() => {
    state.activeHref.setHref("/sites");
    state.breadCrumbs.setCrumbs(
      <Breadcrumbs navigate={navigate} site={siteName} />
    )
  }, []);

  useEffect(() => {
    state.breadCrumbs.setCrumbs(
      <Breadcrumbs navigate={navigate} site={siteName} />
    )
  }, [siteName]);

  if ((data.siteData !== null && data.siteData.hasOwnProperty('error'))) {
      return (
        <ContentLayout
          header={
            <Header variant="h1">
              Error
            </Header>
          }
        >
          <Container header={<Header variant="h2">An Error Has Occured</Header>}>
            {data.siteData.error}
          </Container>
        </ContentLayout>
      )
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onDeleteConfirm = () => {
    console.log("Will delete: ", data.siteData);
    setShowDeleteModal(false);
    ShowNotifications({
      child: "sites",
      flashbar: state.flashBar,
      header: `Requested deletion of site:`,
      message: data.siteData.name,
    });
    setTimeout(() => setReload(true), 1500)
  };

  const [showRenameModal, setShowRenameModal] = useState(false);
  const onRenameInit = () => setShowRenameModal(true);
  const onRenameDiscard = () => setShowRenameModal(false);
  const onRenameConfirm = (newName) => {
    setShowRenameModal(false);
    putSite(
      data.siteData,
      "rename",
      { name: newName },
      state.flashBar,
      // setTimeout(() => props.setReloadData(true), 1000)
    )
    setTimeout(() => setReload(true), 1500)
  };

  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const onSuspendInit = () => setShowSuspendModal(true);
  const onSuspendDiscard = () => setShowSuspendModal(false);
  const onSuspendConfirm = () => {
    const suspendAction = (data.siteData.status === "active") ? "suspend" : "resume";
    setShowSuspendModal(false);
    putSite(
      data.siteData,
      suspendAction,
      undefined,
      state.flashBar,
      // (site === Suspended[Suspended.length - 1]) ? setTimeout(() => props.setReloadData(true), 1500) : null,
    );
    setTimeout(() => setReload(true), 1500)
  };

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const onUpgradeInit = () => setShowUpgradeModal(true);
  const onUpgradeDiscard = () => setShowUpgradeModal(false);
  const onUpgradeConfirm = (newVersion) => {
    setShowUpgradeModal(false);
    putSite(
      data.siteData,
      "upgrade",
      { targetversionid: newVersion.value },
      state.flashBar,
    );
    setTimeout(() => setReload(true), 1500)
  };

  const [showBackupModal, setShowBackupModal] = useState(false);
  const onBackupInit = () => setShowBackupModal(true);
  const onBackupDiscard = () => setShowBackupModal(false);
  const onBackupConfirm = (backupNote) => {
    setShowBackupModal(false);
    // console.log("Will backup: ", backupsite);
    // console.log("To version: ", backupNote);
    putSite(
      data.siteData,
      "backup",
      { notes: backupNote },
      state.flashBar,
    );
    setTimeout(() => setReload(true), 1500)
  };

  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const onTemplateInit = () => setShowTemplateModal(true);
  const onTemplateDiscard = () => setShowTemplateModal(false);
  const onTemplateConfirm = () => {
    setShowTemplateModal(false);
    // console.log("Will template: ", !templated.template);
    putSite(
      data.siteData,
      "istemplate",
      { template: !templated.template },
      state.flashBar,
    );
    setTimeout(() => setReload(true), 1500);
  };

  useEffect(() => {
    if (reload) {
      loadData(data);
      setTimeout(() => setReload(false), 500); // this gives the spinner time to show.
    }
  }, [reload]);

  useEffect(() => {
    if (siteData?.status === "pending") {
      const intervalId = setInterval(() => {
        // console.log("Reloading data because status is pending");
        loadData(data);
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [siteData]);

  return (
    <ContentLayout
      header={
        <SiteDetailsHeader
          buttons={
            [
              { text: "", iconName: "refresh", loading: reload, onClick: () => setReload(true) },
              {
                text: 'Actions',
                items: [
                  {
                    text: 'Rename',
                    id: 'rename',
                    disabled: (data.siteData?.status !== "active"),
                    disabledReason: "You can only rename active sites.",
                  },
                  {
                    text: 'Suspend',
                    id: 'suspend',
                    disabled: (data.siteData?.status !== "active"),
                    disabledReason: "You can only suspend active sites.",
                  },
                  {
                    text: 'Unsuspend',
                    id: 'unsuspend',
                    disabled: (data.siteData?.status !== "suspended"),
                    disabledReason: "You can only unsuspend suspended sites.",
                  },
                  {
                    text: 'Upgrade',
                    id: 'upgrade',
                    disabled: (data.siteData?.status !== "active"),
                    disabledReason: "You can only upgrade active sites.",
                  },
                  {
                    text: 'Backup',
                    id: 'backup',
                    disabled: (data.siteData?.status !== "active"),
                    disabledReason: "You can only backup active sites.",
                  },
                  // For this to work, we need to move setActiveTabId to the parent component.
                  // and pass it through as props. TODO.
                  // {
                  //   text: 'Restore',
                  //   id: 'restore',
                  //   href: `/sites/${data.siteId}?tab=backups`,
                  //   disabled: (data.siteData?.status !== "active"),
                  //   disabledReason: "You can only restore active sites.",
                  // },
                  {
                    text: "",
                    items: [
                      {
                        text: 'Delete',
                        id: 'delete',
                        disabled: (data.siteData?.status !== "active"),
                        disabledReason: "You can only delete active sites.",
                      },
                    ]
                  },
                ],
                onItemClick: (event) => {
                  switch (event.detail.id) {
                    case 'backup':
                      onBackupInit(true);
                      break;
                    // case 'restore':
                    //   event.preventDefault();
                    //   // setActiveTabId('backups');
                    //   navigate(event.detail.href);
                    //   break;
                    case 'suspend':
                    case 'unsuspend':
                      onSuspendInit();
                      break;
                    case 'upgrade':
                      onUpgradeInit();
                      break;
                    case 'rename':
                      onRenameInit();
                      break;
                    case 'delete':
                      onDeleteInit();
                      break;
                    default:
                      console.log("Unknown action: ", event.detail.id);
                  }
                },
              },
              {
                text: "Login as admin",
                id: "login",
                href: (data.siteData?.name ? `https://${data.siteData.name}` : "#"),
                target: "_blank",
                disabled: (data.siteData?.status === undefined),
                disabledReason: "Available on a single site only",
                loading: loginloading,
                onClick: (event) => {
                  event.preventDefault();
                  if (data.siteData?.status !== undefined) {
                    console.log("Will login to: ", data.siteData.name);
                    sitelogin(data.siteData.name, setLoginLoading, state.flashBar);
                  }
                }
              }
              // { text: 'Edit' },
              // { text: 'Delete', onClick: () => onDeleteInit(true) },
            ]
          }
          site={siteName}
        />
      }
    >
      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        sites={[data.siteData || {name: "Loading..."}]}
      />
      <RenameModal
        visible={showRenameModal}
        onDiscard={onRenameDiscard}
        onRename={onRenameConfirm}
        site={data.siteData || {name: "Loading..."}}
      />
      <SuspendModal
        visible={showSuspendModal}
        onDiscard={onSuspendDiscard}
        onSubmit={onSuspendConfirm}
        sites={[data.siteData || {name: "Loading..."}]}
        action={data.siteData?.status === "suspended" ? "Unsuspend" : "Suspend"}
      />
      <UpgradeModal
        visible={showUpgradeModal}
        onDiscard={onUpgradeDiscard}
        onUpgrade={onUpgradeConfirm}
        site={data.siteData}
      />
      <BackupModal
        visible={showBackupModal}
        onDiscard={onBackupDiscard}
        onBackup={onBackupConfirm}
        site={data.siteData}
      />
      <TemplateModal
        visible={showTemplateModal}
        onDiscard={onTemplateDiscard}
        onSubmit={onTemplateConfirm}
        sites={[data.siteData || {name: "Loading..."}]}
        action={data.siteData?.template === true ? "Unset as template" : "Set as template"}
      />
      <Content data={data} flashBar={state.flashBar}/>
    </ContentLayout>
  );
}
